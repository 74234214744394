
import { Component, Vue } from 'vue-property-decorator'
import { Page, Table } from '../../types/common'
import { ExperienceData, Tags, Scope } from '../../types/experienceApply'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'experienceList'
})
@KeepAlive
export default class extends Vue {
  private dateRange = []
  private searchInfo = {
    productName: '',
    type: '',
    labelId: '',
    startTime: '',
    endTime: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        label: '排序码',
        prop: 'orderNum',
        minWidth: '50',
        showOverflowTooltip: true
      },
      {
        label: '产品名称',
        prop: 'productName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '功能描述',
        prop: 'productDescribe',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '产品类型',
        prop: 'type',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '业务标签',
        prop: 'labelIdNames',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '详情地址',
        prop: 'url',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '操作人',
        prop: 'addUserName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '最后操作时间',
        prop: 'utime',
        minWidth: '120',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private businessTagList: Tags[] = []
  private oldData: { [key: number]: string } = {}
  private tagShow = false
  private isEdit = false
  private tagLoading = false

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getBusinessTagList()
    this.getData()
  }

  // 获取业务标签列表
  getBusinessTagList () {
    this.$axios.get(this.$apis.experienceApply.selectTenantProductLabelByList).then(res => {
      this.businessTagList = res.list || []
    })
  }

  // 新增业务标签
  onAdd () {
    if (!this.isEdit) {
      this.businessTagList.push({
        labelName: '',
        isInput: true
      })
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的标签')
    }
  }

  // 取消业务标签
  onCancel (scope: Scope) {
    if (scope.row.labelId) {
      // 是否为编辑
      this.businessTagList[scope.$index].isInput = false
      this.businessTagList[scope.$index].labelName = this.oldData[scope.$index] // 取消值不变
    } else {
      this.businessTagList.splice(scope.$index, 1)
    }
    this.isEdit = false
  }

  // 保存业务标签
  onSave (row: Tags) {
    if (row.labelName) {
      const info = row.labelId
        ? {
          labelId: row.labelId,
          labelName: row.labelName
        }
        : {
          labelName: row.labelName
        }

      const url = row.labelId
        ? this.$apis.experienceApply.updateTenantProductLabel
        : this.$apis.experienceApply.insertTenantProductLabel

      this.$axios.post(url, info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.getBusinessTagList()
      })
    } else {
      this.$message({
        message: '请输入标签名称',
        type: 'warning'
      })
    }
    this.isEdit = false
  }

  // 编辑业务标签
  onEdit (scope: Scope) {
    if (!this.isEdit) {
      this.businessTagList[scope.$index] = {
        ...scope.row,
        isInput: true
      }
      this.oldData[scope.$index] = scope.row.labelName
      this.$set(this.businessTagList, scope.$index, this.businessTagList[scope.$index]) // 重新渲染表格数据
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的标签')
    }
  }

  // 删除业务标签
  onDelete (labelId: string) {
    if (!this.isEdit) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.tagLoading = true
          this.$axios.post(this.$apis.experienceApply.deleteTenantProductLabel, { labelId }).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getBusinessTagList()
          }).finally(() => {
            this.tagLoading = false
          })
        })
    } else {
      this.$message.warning('请先保存正在编辑的标签')
    }
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<ExperienceData>>(this.$apis.experienceApply.selectTenantProductByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  dateRangeChange (date: any) {
    if (date) {
      this.searchInfo.startTime = date[0]
      this.searchInfo.endTime = date[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 业务标签管理关闭
  closeBusinessTag () {
    this.isEdit = false
    this.tagShow = false
    this.getData()
    this.getBusinessTagList()
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'experienceDetail',
      params: { id }
    })
  }

  // 编辑
  onUpdate (id: string) {
    this.$router.push({
      name: 'experienceEdit',
      params: { id }
    })
  }
}
